import { Trans } from 'react-i18next';
import { Button, Icon } from './LogoutBtn.styled.js';
import { useDispatch } from 'react-redux';

import { useEffect, useState } from 'react';
import LogoutModal from 'components/modal/LogoutModal/index.js';
import { Text1 } from '../UserNav/UserNav.styled.js';



const LogoutBtn = ({ isHovered, onboard }) => {
  const dispatch = useDispatch();
  const [isModalOpen, setModalOpen] = useState(false);

  const handleOpenModal = () => {
    setModalOpen(true);
    document.body.style.overflow = 'hidden';
  };

  const handleCloseModal = () => {
    setModalOpen(false);

    document.body.style.overflow = 'auto';

  };

  return (
    <>
      <Button
        type="button"

        onClick={handleOpenModal}
      >
        <Icon className={`${isHovered || onboard ? 'hovered' : ''}`} />
        {isHovered || onboard ? <Text1>Вийти з акаунту</Text1> : ""}

      </Button>
      {
        isModalOpen && (
          <LogoutModal onClose={handleCloseModal} />
        )
      }</>
  );
};

export default LogoutBtn;
