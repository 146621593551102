

import React, { useState } from 'react';
import { TooltipText, TooltipWrapper, Triangle, Text } from './Tooltip.styled';




const Tooltip = ({one, content, children }) => {
  const [visible, setVisible] = useState(false);

  return (
    <TooltipWrapper
  
      onMouseEnter={() => setVisible(true)}
      onMouseLeave={() => setVisible(false)}
    >
      {children}
      <TooltipText className={one} visible={visible}>
        <Triangle className={one} />
        <Text>{content}</Text></TooltipText>
    </TooltipWrapper>
  );
};

export default Tooltip;