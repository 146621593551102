import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import { ReactComponent as PlusIcon } from '../../../images/Plus.svg';
export const ButtonWrap = styled.div`

display: flex;
align-items: center;
justify-content: space-between;
gap:12px;
width:100%;


`;
export const ModalTitle = styled.h2`
display: flex;
align-items: center;
justify-content: center;
gap: 10px;
font-family: var(--font-family);
font-weight: 600;
font-size: 20px;
line-height: 135%;
text-align: center;
color: var(--colors-grey-900);
width:100%;
`;



export const CancelButton = styled.button`
font-family: var(--font-family);
font-weight: 600;
font-size: 16px;
line-height: 137%;
text-align: center;
width:calc((100% - 12px) / 2);

display: flex;
align-items: center;
color: var(--colors-primary-600);
background:none;
border: 1px solid var(--colors-primary-600);
border-radius: 360px;
padding: 8px 12px;
justify-content: center;
height: 48px;
`;

export const SaveButton = styled.button`
font-family: var(--font-family);
font-weight: 600;
font-size: 16px;
line-height: 137%;
text-align: center;
color: var(--colors-grey-25);
display: flex;
justify-content: center;
width:calc((100% - 12px) / 2);
align-items: center;
background:${props => props.disabled ? 'var(--colors-grey-200)' : 'var(--colors-primary-600)'};
border-radius: 360px;
padding: 8px 12px;
height: 48px;

`;





export const DetailModalBox = styled.ul`
background: none;
width: 100%;
height: 70vh;
margin: 24px 0;
display: flex;
flex-direction: column;
gap:16px;
`;
export const DetailModalItem = styled.li`

width: 100%;

display: flex;
cursor: pointer;
flex-direction: column;
gap:8px;
`;

export const DetailModalLabel = styled.label`
font-family: var(--font-family);
font-weight: 600;
font-size: 14px;
line-height: 150%;
color: var(--colors-grey-800);
width: 100%;
`;

export const DetailModalInput = styled.div`
appearance: none;
outline: none;
border: 1px solid var(--colors-grey-100);
border-radius: 16px;
padding:  16px;
width: 100%;
height: 100%;
overflow-y: auto;

background: var(--colors-grey-25);
font-family: var(--font-family);
font-weight: 500;
font-size: 16px;
line-height: 137%;
color: var(--colors-grey-900);
mask-image: inset(0 0 0 0 round 16px);
`;