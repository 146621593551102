import styled from '@emotion/styled';
import { ReactComponent as BeakIcon } from '../../../images/Beak.svg'
// Styled components for Tooltip
export const TooltipWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

`;

export const TooltipText = styled.div`
  visibility: ${props => props.visible ? 'visible' : 'hidden'};
background: var(--colors-primary-600);
 color: var(--colors-grey-25);
  text-align: center;
border-radius: 16px;
padding: 8px 12px;
width: 319px;
white-space:wrap;
  position: absolute;
  z-index: 1;
  top: calc(100% + 26.5px); /* Position above the element */
  left: -24px;
 
  opacity: ${props => props.visible ? '1' : '0'};
  transition: opacity 0.3s;
  @media (max-width: 1440px) {
    &.four{
   left: unset;
   right: -28px;
}
  } 
@media (max-width: 1024px) {
    &.two{

     left: unset;
   right: -28px;
  }
}
`;

/* &::before{
content: "";
width: 0;
height: 0;
border-left: 20px solid transparent;
border-right: 20px solid transparent;
border-bottom: 18px solid red;
position: absolute;
top:-12px;
left:13px;
background: none;


} */

export const Text = styled.p`

position:relative;
z-index: 2;
 font-family: var(--font-family);
font-weight: 500;
font-size: 14px;
line-height: 150%;
text-align: center;
width: 100%;
`;
export const Triangle = styled(BeakIcon)`

  width: 56px;
  height: 29px;
  /* border-left: 18px solid transparent;
  border-right: 18px solid transparent;
  border-bottom: 18px solid var(--colors-primary-600);; */
  position: absolute;
  top:-12px;
    left:0px;
  background: none;
@media (max-width: 1440px) {
  &.four{

    left:unset;
    right:13px;
}

  } 
@media (max-width: 1024px) {
    &.two{

    left:unset;
    right:13px;
}
    
  } 

`;
// export const Triangle = styled(BeakIcon)`
//   position: absolute;
//   top: -15px;
//   left: 15px;
//   width: 20px;
//   height: 10px;
//   background: var(--colors-primary-600);
//   clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
//   border-radius: 5px; /* Додає заокруглення */
//   transform: rotate(180deg); /* Обертає трикутник */
// `;