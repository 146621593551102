import React, { useState } from 'react';
import Modal from '../Modal';

import { ModalTitle } from './OnboardModal.styled';


import OnboardBody from './OnboardBody/OnboardBody';
import { useSearchParams } from 'react-router-dom';


const OnboardModal = ({ onClose, type, onOpen }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const onboard = searchParams.get('onboard');
  console.log('type :>> ', type);



  return !onboard && (
    <Modal onClose={onClose}>

   <OnboardBody onClose={onClose} type={type} onOpen={onOpen} />

    </Modal>
  );
};

export default OnboardModal;
