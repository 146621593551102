import { Global, css } from '@emotion/react';

const globalStyles = css`
  :root {
    --white-color: #ffffff;
    --button-bgd-color: #3e85f3;
    --color-task-low-priority: #3e85f3;
    --bgc-task-low-priority: #ceeefd;
    --color-task-medium-priority: #f3b249;
    --bgc-task-medium-priority: #fcf0d4;
    --color-task-high-priority: #ea3d65;
    --bgc-task-high-priority: #ffd2dd;
    accent-color:var(--colors-secondary-500); 
    
  }
::-webkit-scrollbar {
    width: 6px;
     height: 6px; 
  }

  ::-webkit-scrollbar-thumb {
    background-color: var( --colors-grey-400);
    border-radius: 6px;
    opacity:0.2;
  }

  ::-webkit-scrollbar-track {
    background-color: var(--
      --colors-grey-100);
    border-radius: 6px;
  }
  body {
    font-family: var(--font-family);
    background: var(--colors-grey-25);
    color: #171820;

    margin: 0;
    padding: 0;
  }
button{
  cursor: pointer;

}
svg{
  cursor: pointer;
}
ul,
  ol,
  li {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  h1,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    margin: 0;
  }

  a {
    text-decoration: none;
  }

body{
  font-family: var(--font-family);
}
input:focus, textArea:focus{
border: 1px solid var(--colors-primary-600);
}

  img {
    display: block;
    width: 100%;
    height: auto;
  }
  .recharts-layer {
  }
  button{
    border: none;
  }
  .icohovered{
    width: 56px;
    height: 56px;
  }

.toast-error-custom {
  box-shadow: 0 2px 8px 0 rgba(152, 152, 179, 0.16);
background: var(--colors-error-50); /* Червоний фон для помилки */
 border: 1px solid var(--colors-error-500);
border-radius: 16px;
padding: 8px 12px;
font-family: var(--font-family);
font-weight: 500;
font-size: 16px;
line-height: 137%;
color: var(--colors-grey-900);
height: 40px;
min-height: 40px;
min-width: 400px;/* Розмір шрифту */
display: flex;
gap: 12px;
width:fit-content;
align-items: center;
box-sizing: border-box;
width: 80vh;
}

/* Стилі для успіху */
.toast-success-custom {
  border: 1px solid var(--colors-success-600);
 border-radius: 16px;
padding: 8px 12px;
font-family: var(--font-family);
font-weight: 500;
font-size: 16px;
line-height: 137%;
color: var(--colors-grey-900);
min-height: 40px;
min-width: 400px;/* Розмір шрифту */
width: 100%;
display: flex;
gap: 12px;
width:fit-content;
align-items: center;
box-sizing: border-box;

}

/* Стилі для прогрес-бару */
.toast-progress-custom {
  background-color: yellow; /* Жовтий прогрес-бар */
}
.toast-warning-custom {
box-shadow: 0 2px 8px 0 rgba(152, 152, 179, 0.16);
background: var(--colors-warning-50);
border: 1px solid var(--colors-warning-500);
border-radius: 16px;
 padding: 8px 12px;
font-family: var(--font-family);
font-weight: 500;
font-size: 16px;
line-height: 137%;
color: var(--colors-grey-900);
min-height: 40px;
min-width: 400px;/* Розмір шрифту */
width: 100%;
display: flex;
gap: 12px;
width:fit-content;
align-items: center;
box-sizing: border-box;

}


.toast-info-custom{
box-shadow: 0 2px 8px 0 rgba(152, 152, 179, 0.16);
background: var(--colors-primary-50);
border: 1px solid var(--colors-primary-600);

border-radius: 16px;
 padding: 8px 12px;
font-family: var(--font-family);
font-weight: 500;
font-size: 16px;
line-height: 137%;
color: var(--colors-grey-900);
min-height: 40px;
min-width: 400px;/* Розмір шрифту */
width: 100%;
display: flex;
gap: 12px;
width:fit-content;
align-items: center;
box-sizing: border-box;

}

.toast-question-custom{
box-shadow: 0 2px 8px 0 rgba(152, 152, 179, 0.16);
background: var(--colors-grey-25);
border: 1px solid var(--colors-grey-400);
border-radius: 16px;
 padding: 8px 12px;
font-family: var(--font-family);
font-weight: 500;
font-size: 16px;
line-height: 137%;
color: var(--colors-grey-900);
min-height: 40px;
min-width: 400px;/* Розмір шрифту */
width: 100%;
display: flex;
gap: 12px;
width:fit-content;
align-items: center;
box-sizing: border-box;

}


.Toastify__toast>.Toastify__toast-body{
  padding: 0;
  margin: 0;
  display: flex; 
gap: 12px;
align-items: center;
}

.Toastify__toast>.Toastify__toast-body>.Toastify__toast-icon{
  
width: 24px;
height: 24px;
}
.Toastify__toast>.Toastify__toast-body>div+div{
width: fit-content;
word-break: unset;
white-space: normal;
width: 80%;
}

.Toastify__close-button{
 color:var(--colors-grey-400);
    background: transparent;
    outline: none;
    border: none;
    padding: 0;
    cursor: pointer;
    opacity: 0.7;
    transition: 0.3s ease;
    align-self: flex-start;
    z-index: 1;
    top:50%;
   
    height: 16px;
transform: translateY(-50%);
position: absolute;
right: 10px;
}
.Toastify__close-button.--light{
 color:var(--colors-grey-400);

}

.fNumb{ 
  display:flex;
  white-space: nowrap;
  @media (max-width: 1024px) {
   display:none;
  }
}

.mNumb{
 display:none;
  @media (max-width: 1024px) {
      display:block; 
  }
}

`;






const GlobalStyles = () => <Global styles={globalStyles} />;

export default GlobalStyles;
