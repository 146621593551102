import styled from '@emotion/styled';
import { CgClose } from 'react-icons/cg';

export const Container = styled.aside`

  display: flex;


  height: 100vh;
  background-color: var(--main-background-color);


    position: static;
    width: 88px;
      transition: width 0.3s ease;
  &.hovered{
    width: 256px;
    min-width: 256px;
    max-width: 256px;
      transition: width 0.3s ease;
  }

  @media (max-width: 1440px) {
width: 72px;
  &.hovered{
    width: 72px;
    min-width: 72px;
    max-width: 72x;
      transition: unset;
  }
  }




`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  height: 100%;
  width:100%
 

`;

export const Menu = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

`;


export const CloseBtn = styled(CgClose)`
  right: 20px;
  width: 24px;
  height: 24px;
  color: var(--modal-close-icon);
  cursor: pointer;
  z-index: 100;

`;

export const Image = styled.img`
  width: 36px;
  height: 35px;
  margin-right: 6px;
  object-fit: cover;
  object-position: center;


`;

export const Text = styled.p`
  color: var(--accent-color);
  text-shadow: 0px 9.3px 57px rgba(0, 0, 0, 0.04),
    0px 47px 355px rgba(0, 0, 0, 0.07);
  font-size: 16px;
  font-weight: 700;
  line-height: 1.37;
  margin-right: 25px;

  /* @media screen and (min-width: 768px) and (max-width: 1439px) {
    line-height: 1.5;
    margin-right: 31px;
  }

  @media screen and (min-width: 1440px) {
    font-size: 24px;
    line-height: 1;
    margin-right: 0;
  } */
`;

export const Span = styled.span`
  font-style: italic;
`;
