import styled from '@emotion/styled';

import { ReactComponent as Notification } from '../../../images/Notifications.svg';
import { ReactComponent as Back } from '../../../images/arrow/left.svg';
export const Container = styled.header`
  display: flex;
  align-items: center;
  padding-bottom: 24px;
  width: 100%;
min-width: 1024px;
   @media (max-width: 1440px) {
     padding-bottom: 20px;
min-width: 872px;
   };
     @media (max-width: 1024px) {
  
min-width: 616px;
   };
`;

export const Wrapper = styled.div`
  display: ${props => (props.hide = 'true' ? 'none' : 'flex')};
  margin-right: auto;

  /* @media screen and (min-width: 1440px) {
    display: ${props => (props.hide = 'true' ? 'flex' : 'none')};
  } */
`;

export const Picture = styled.picture`
  width: 64px;
  height: 60px;
  margin-right: 8px;
`;

export const Imeges = styled.img`
  width: 64px;
  height: 60px;
`;

export const WrapperText = styled.div`
  display: grid;
  gap: 8px;
`;

export const PageHeader = styled.p`
  /* display: ${props => (props.hide = 'true' ? 'none' : 'block')}; */
  display: block;
  color: var(--colors-grey-900);
  margin-right: ${props => (props.tasksСompleted ? 'auto' : 0)};

    font-weight: 600;
font-size: 24px;
line-height: 125%;

  @media  (max-width: 1440px) {
  
  }
    @media  (max-width: 1024px) {
    
  }
`;

export const NotificWrap = styled.div`
  margin-left: auto;
  position: relative;
   &::after{
    display: ${props => props.hide === "true" ? "block" : "none"};
  content: "";
  position: absolute;
  right: 0;
  top:0;
background: var(--colors-error-500);
  border-radius: 360px;
width: 12px;
height: 12px;
 }
`;
export const NotificBtn = styled(Notification)`


  width: 32px;
  height: 32px;

  cursor: pointer;
 color: var(--colors-grey-400);



 
`;

export const BackIcon = styled(Back)`


  width: 32px;
  height: 32px;
 

 color: var(--colors-grey-400);


 
`;

export const ButtonBack = styled.div`
font-family: var(--font-family);
font-weight: 500;
font-size: 20px;
line-height: 135%;
color: var(--colors-grey-500);
display: flex;
gap:12px ;
align-items: center;
  cursor: pointer;
`