/** @jsxImportSource @emotion/react */
import React from 'react';
import styled from '@emotion/styled';

const StyledNumberWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

width: 100%;
height: 100%;

`;

const BoldText = styled.span`
font-family: var(--font-family);
font-weight: 700;
font-size: 16px;
line-height: 137%;
text-align: center;
color:${props => props.color};
`;

const NormalText = styled.span`
font-family: var(--font-family);
font-weight: 500;
font-size: 16px;
line-height: 137%;
text-align: center;
color:${props => props.color};
`;

const FormatNumber = ({number,color} ) => {
    // Форматування номера
    console.log('number :>> ', number);
    const formatNumber = (num) => {
        if (!num) return '';
        const str = num.toString();
        return `${str.slice(0, 4)}-${str.slice(4, 9)}-${str.slice(9, 14)}-${str.slice(14)}`;
    };

    // Відокремлення останніх 4 цифр
    const boldLastFour = (formattedNum) => {
        if (!formattedNum) return null;
        const plainString = formattedNum.replace(/-/g, '');
        const lastFour = plainString.slice(-4);
        const withoutLastFour = formattedNum.slice(0, -5);
        return (
            <>
                <NormalText color={color}>{withoutLastFour}</NormalText>
                <BoldText color={color}>{lastFour}</BoldText>
            </>
        );
    };

    const formattedNumber = formatNumber(number);

    return <StyledNumberWrapper >{boldLastFour(formattedNumber)}</StyledNumberWrapper>;
};

export default FormatNumber;
