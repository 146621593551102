import styled from '@emotion/styled';
import { CgClose } from 'react-icons/cg';
import Arrow from '../../../images/Beak2.svg'

export const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
min-width:1440px ;

  display: flex;
  justify-content: center;

  align-items: center;
  z-index: 10;

    @media (max-width: 1440px) {
   min-width: 768px; 
 
  }


`;

export const ModalOverlayLeft = styled.div`

  
  width: 256px;
  height: 100%;
 min-width: 256px;

  display: flex;
  justify-content: center;

  align-items: center;
  @media (max-width: 1440px) {
   width: 72px; 
  min-width: 72px;
  }


`;
export const ModalOverlayRight = styled.div`

  width:calc(100% - 256px);
  height: 100%;
  min-width:1184px;
  background:rgba(29, 29, 57, 0.5); 

  display: flex;

  
  z-index: 10;
  @media (max-width: 1440px) {
min-width: 696px;  
 width:calc(100% - 72px);
  }

  @media (max-width: 1024px) {
     min-width: 696px; 
  }


`;

export const ModalContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: space-between;
  flex-direction: column;
height:fit-content;
left:28px;

 border-radius: 16px;
padding: 8px 12px;
width: 400px;
  box-shadow: 0 0 40px 0 rgba(195, 195, 195, 0.25);
  background: var(--colors-grey-25);
  position: relative;
  z-index: 1100;
 transition: transform 0.3s ease-in;
&::before{
  content: '';
  width: 21px;
  height: 64px;
  background-image: url(${Arrow});
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  left:-12px;
  top: 50%;
  z-index: 12;
  transform:translateY(-50%);
 
}

&.board1{
  transform:translateY(101.5px);
}
&.board2{
  transform:translateY(161.5px);
}
&.board3{
  transform:translateY(221.5px);
}

&.board4{
  transform:translateY(calc(80vh - 113.5px));
}
&.board5{
  transform:translateY(calc(80vh - 43.5px));
}
&.board6{

transform:translate(-344px, 125.5px);
  &::before{

transform:rotate(180deg) translateY(50%);
  left:unset;
  right:-12px;

 
}


}

   @media (max-width: 1440px) {
  &.board1{
  transform:translateY(68.5px);
}
&.board2{
  transform:translateY(128.5px);
}
&.board3{
  transform:translateY(188.5px);
}

&.board4{
  transform:translateY(calc(80vh - 118.5px));
}
&.board5{
  transform:translateY(calc(80vh - 48.5px));
}
&.board6{

transform:translate(-310px, 110.5px);
  &::before{

transform:rotate(180deg) translateY(50%);
  left:unset;
  right:-12px;

 
}


}
 
  }


   
`;

export const CloseBtn = styled(CgClose)`
  position: absolute;
  top: 8px;
  right: 12px;
  width: 24px;
  height: 24px;
  
  color: var(--colors-grey-400);
  transition: all 250ms cubic-bezier(0.4, 0, 0.2, 1);
  cursor: pointer;

  :hover {
    color: var(--accent);
  }

 
`;
