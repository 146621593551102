import { createPortal } from 'react-dom';
import {

  ModalContent,
  ModalOverlay,
  CloseBtn,
} from './Modal.styled';
import ImageAnimation3 from 'components/layout/loader/ImageAnimation3';

const modalRoot = document.querySelector('#root-module');

const Modal = ({ children, onClose, pad, heightT = false, loading = 1, }) => {
  console.log('loading :>> ', loading);
  const handleContentClick = event => {
    event.stopPropagation();
  };

  return createPortal(
    <ModalOverlay onClick={onClose}>
  {    loading===0? <ImageAnimation3/>:
      <ModalContent pad={pad} onClick={handleContentClick} heightT={heightT ? 'true' : 'false'} >

          {pad !== "false"  && <CloseBtn onClick={onClose} />}

        {children}
      </ModalContent>}
    </ModalOverlay>,
    modalRoot
  );
};

export default Modal;
