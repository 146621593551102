import React, { useEffect, useState } from 'react';
import Modal from '../Modal';


import { BodyBox, ButtonWrap, CancelButton, DetailModalAddres, DetailModalAddress, DetailModalBox, DetailModalId, DetailModalInput, DetailModalItem, DetailModalLabel, DetailModalSpan, ModalTitle, Popup, PopupBox, SaveButton } from './OrdersConfirm.styled';
import { useDispatch } from 'react-redux';
import { updateUser } from '../../../redux/auth/operations';
import { useConfirmOrderMutation, useGetOrderQuery, useGetOrdersQuery } from '../../../redux/orders/ordersApi';

import { showErrorToast, showSuccessToast } from 'utils/showToast';
import ImageAnimation1 from 'components/layout/loader/ImageAnimation1';
import ImageAnimation2 from 'components/layout/loader/ImageAnimation2';
import ImageAnimation from 'components/layout/loader/ImageAnimation';
import ImageAnimation3 from 'components/layout/loader/ImageAnimation3';
import { se } from 'date-fns/locale';
import FormatNumber from 'components/other/FormatNumber/FormatNumber';

const OrdersConfirm = ({ onClose, orderArrayId }) => {
  const [selectedPopup, setSelectedPopup] = useState(orderArrayId[0]);
  const { data: order, isLoading: isLoadingOrder } = useGetOrderQuery(selectedPopup);
  const [confirmOrder, ] = useConfirmOrderMutation();


useEffect(()=>{if(order)console.log('order :>> ', order);},[order])


  const handleSave = async () => {

   
    try {
     const data= await confirmOrder(orderArrayId[0])
      console.log('data :>> ', Number(data?.data?.status) < 300);
      if (Number(data?.data?.status)<300) {
        showSuccessToast('Кошик видано')
        onClose();
      } else {
        console.log('dataewqdrwq :>> ', data);
        showErrorToast(data?.error?.data?.message ? data?.error?.data?.message :'Помилка')
        data.error.data.details.map(item => showErrorToast(item))
        
}
        
      console.log('Details  successfully');
   


    } catch (error) {
      console.error('Failed to Details:', error);
      console.log('error :>> ', error);
      showErrorToast("Помилка")
    }
    onClose();
  };


  return (

    <Modal pad={'false'} onClose={onClose} loading={isLoadingOrder?0:1}>
      <PopupBox>
        {orderArrayId.map(item => {
          return (
            <Popup count={orderArrayId.length < 8 ? orderArrayId.length:7} className={item === selectedPopup ? 'activepopup' : null} key={item} onClick={() => {
              if (item === selectedPopup) return;
              setSelectedPopup(item)
            }}>
              {item}
            </Popup>
          )
        })}
      </PopupBox>
      <BodyBox>
      <ModalTitle>Видати замовлення</ModalTitle>
          {order && <DetailModalBox>
            <DetailModalAddress>{`${order?.shop.name} (${order?.shop.address})`}</DetailModalAddress>
          <DetailModalId><FormatNumber number={order?.key} color={'var(--colors-secondary-25)'} /> </DetailModalId>
            <DetailModalItem>
          <DetailModalLabel>{order?.bag?.name}</DetailModalLabel>
              <DetailModalSpan>{order?.count} шт</DetailModalSpan>
            </DetailModalItem>
          </DetailModalBox>}
      <ButtonWrap>
      
          <SaveButton onClick={onClose} >Видано отримувачу</SaveButton> 
          </ButtonWrap>
      </BodyBox>
    </Modal>
     
  );
};

export default OrdersConfirm;