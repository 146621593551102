import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import { ReactComponent as PlusIcon } from '../../../images/Plus.svg';
export const ButtonWrap = styled.div`

display: flex;
align-items: center;
justify-content: center;
gap:12px;
width:100%;


`;
export const PopupBox = styled.ul`
display: flex;
align-items: center;
overflow-x: auto;
width: 100%;
height: 46px;
background: var(--colors-grey-50);
max-width:426px;

`;
export const Popup = styled.li`
cursor: pointer;
display: flex;
align-items: center;
justify-content: center;
font-family: var(--font-family);
font-weight: 500;
font-size: 16px;
line-height: 137%;
text-align: center;
color: var(--colors-grey-400);
background:none;
height: 100%;
padding: 12px 8px;
width:${props => props.count ? `calc(426px / ${props.count})` : "100%"};
min-width:${props => props.count ? `calc(426px / ${props.count})` : "100%"};
&.activepopup{
    cursor: unset;
border-bottom: 2px solid var(--colors-primary-500);
background: var(--colors-primary-100);
color: var(--colors-primary-600);
}
`;
export const BodyBox = styled.div`
padding: 24px 12px 12px 12px;
`;
export const ModalTitle = styled.h2`
font-family: var(--font-family);
font-weight: 600;
font-size: 20px;
line-height: 135%;
text-align: center;
color: var(--colors-grey-900);
width:100%; 
`;



export const CancelButton = styled.button`
font-family: var(--font-family);
font-weight: 600;
font-size: 16px;
line-height: 137%;
text-align: center;
width:calc((100% - 12px) / 2);

display: flex;
align-items: center;
color: var(--colors-primary-600);
background:none;
border: 1px solid var(--colors-primary-600);
border-radius: 360px;
padding: 8px 12px;
justify-content: center;
height: 48px;
`;

export const SaveButton = styled.button`
font-family: var(--font-family);
font-weight: 600;
font-size: 16px;
line-height: 137%;
text-align: center;
color: var(--colors-grey-25);
display: flex;
justify-content: center;
width:calc((100% - 12px) / 2);
align-items: center;
background:var(--colors-primary-600);
border-radius: 360px;
padding: 8px 12px;
height: 48px;

`;





export const DetailModalBox = styled.div`
background: none;
width: 100%;
height: fit-content;
margin-bottom: 24px;
display: flex;
flex-direction: column;
/* gap:16px; */
`;

export const DetailModalAddress = styled.p`
font-family: var(--font-family);
font-weight: 500;
font-size: 14px;
line-height: 150%;
text-align: center;
color: var(--colors-grey-500);
width: 100%;
text-align:center;
margin-bottom: 24px;
`;

export const DetailModalId = styled.div`
font-family: var(--font-family);
font-weight: 500;
font-size: 16px;
line-height: 137%;
text-align: center;
color: var(--colors-secondary-25);
border-radius: 16px;
padding: 4px 12px;
width: 100%;
height: 30px;
text-align:center;
background: var(--colors-secondary-500);
margin-bottom: 8px;
justify-content: center;
`;







export const DetailModalItem = styled.div`

border-radius: 16px;
padding: 12px;
width: 100%;
height: 54px;
background: var(--colors-grey-50);
display: flex;
justify-content: space-between;


`;

export const DetailModalLabel = styled.p`
font-family: var(--font-family);
font-weight: 600;
font-size: 16px;
line-height: 137%;
color: var(--colors-grey-800);
width: fit-content;
`;
export const DetailModalSpan = styled.p`
font-family: var(--font-family);
font-weight: 600;
font-size: 16px;
line-height: 137%;
text-align: center;
color: var(--colors-secondary-500);
border-radius: 360px;
padding: 4px 8px;
height: 100%;
background: var(--colors-grey-25);
width: fit-content;
`;



export const DetailModalInput = styled.input`
appearance: none;
outline: none;
border: 1px solid var(--colors-grey-100);
border-radius: 360px;
padding: 8px 16px;
width: 604px;
height: 48px;
background: var(--colors-grey-25);
font-family: var(--font-family);
font-weight: 500;
font-size: 16px;
line-height: 137%;
color: var(--colors-grey-900);
&:hover{
    outline:1px solid var(--colors-primary-600);
}
&::placeholder{
   font-family: var(--font-family);
font-weight: 500;
font-size: 16px;
line-height: 137%;
color: var(--colors-grey-400); 
}
`;